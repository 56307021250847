import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Home from "./components/Home";
import Checkout from "./components/Checkout";
import Success from "./components/Success";
import Fail from "./components/Fail";
import ProductDetail from "./components/ProductDetail";
import About from "./components/About";
import ContacUs from "./components/ContactUs";
import Cart from "./components/Cart";
import Shipping from "./components/Shipping";
import Terms from "./components/Terms";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route
              path="/ProductDetail/:id"
              render={(props) => (
                <ProductDetail key={props.match.params.id} {...props} />
              )}
            />
            <Route path="/Terms" component={Terms}></Route>
            <Route path="/Shipping" component={Shipping}></Route>
            <Route path="/Cart" component={Cart}></Route>
            <Route path="/Contact" component={ContacUs}></Route>
            <Route path="/About" component={About}></Route>
            <Route path="/Success" component={Success}></Route>
            <Route path="/Fail" component={Fail}></Route>
            <Route path="/Checkout" component={Checkout}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </React.Fragment>
      </HashRouter>
    );
  }
}
export default App;
