import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/shipping.scss";
import deliveryImg from "../image/delivery.png";

class Shipping extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="shipping-page">
          <div className="title">Shipping Policy</div>
          <div className="desc-content">
            <p>
              We want to ensure you get the right product at the right time for
              the right price.
            </p>
            <p>
              Processing Time - All online orders are processed the following
              business day. Add 1 day to your shipping estimate for total
              estimated delivery time. Business days are defined as
              Monday-Friday, excluding holidays.
            </p>
            <p>
              Free Shipping - We offer Federal Express Ground Home standard
              delivery for no charge on orders over $35 to the contiguous U.S.
              excluding kennels. You should receive your order in 5 - 7 business
              days. Because subscription products automatically receive free
              shipping, they don't count towards the $35 cart total for free
              shipping.
            </p>
            <p>
              Standard Shipping - For orders $35 or less, we ship for a nominal
              fee to the contiguous U.S.
            </p>
            <p>
              Expedited Shipping - We also offer expedited 1-day or 2-day
              shipping for an additional charge. Certain products including
              batteries and spray refills can only be sent by standard ground
              shipping due to mail regulations.
            </p>
            <p>
              Subscriptions - All subscription orders will receive free ground
              shipping within the contiguous U.S. Call Customer Care if you are
              needing to place an expedited order outside of your subscription.
              This can be done for an additional shipping fee. Because
              subscription products automatically receive free ground shipping,
              they do not count towards the $35 cart total for free shipping.
            </p>
            <p>
              Alaska & Hawaii - Packages sent to Alaska and Hawaii will have
              limited shipping options and are not eligible for free or standard
              shipping.
            </p>
            <p>
              Exclusive to PetSafe.net - We don't offer these shipping options
              or any other discounts or promotions on orders placed with any
              other e-commerce companies that we show on our site under Where to
              Buy.
            </p>
            <p>
              International Orders - We don't ship internationally from this
              website. You can view products available from our retailers in
              other countries on our international site.
            </p>
            <div className="title">Shipping FAQs</div>
            <p>What forms of payment do you accept?</p>
            <p>
              We accept American Express, Visa, MasterCard, and Discover credit
              cards. Note that your bill will say "Radio Systems Corporation,"
              the parent company of PetSafe.
            </p>
            <p>How much is shipping?</p>
            <p>
              Orders over $35 receive free FedEx ground home shipping
              (contiguous U.S. only). Orders under $35 ship for a nominal fee.
              We also offer 2-day and overnight FedEx expedited shipping for an
              additional cost. Orders to Alaska and Hawaii will have limited
              shipping options and aren't eligible for free or standard
              shipping.
            </p>
            <p>How do I cancel my order?</p>
            <p>
              After you place an order, there is a limited time in which you can
              cancel or modify it. Once your order has been processed to our
              warehouse, we cannot make any modifications to the order, or
              cancel it.
            </p>
            <p>
              Please send us a message by filling out this form, or give us a
              call at (866) 738-4379 to speak to a member of our Customer Care
              team. We'll be happy to help you with your order and answer any
              questions.
            </p>
            <p>When will my package arrive?</p>
            <p>
              All orders are processed the following business day. With standard
              FedEx Ground Home Delivery, you can expect your order to arrive
              5-7 business days after the next business day. With 2-Day Express
              Shipping, you can expect your order to arrive 2 business days
              after the next business day. Packages shipped with Standard
              Overnight Shipping will arrive 1 business day after the next
              business day.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Shipping;
