// 1
import col1 from "../image/col1.webp";
import col1_1 from "../image/col1-1.webp";
import col1_2 from "../image/col1-2.webp";
import col1_3 from "../image/col1-3.webp";
import col1_4 from "../image/col1-4.webp";
import col1_5 from "../image/col1-5.webp";

// 2
import col2 from "../image/col2.webp";
import col2_1 from "../image/col2-1.webp";
import col2_2 from "../image/col2-2.webp";
import col2_3 from "../image/col2-3.webp";
import col2_4 from "../image/col2-4.webp";
import col2_5 from "../image/col2-5.webp";

// 3
import col3 from "../image/col3.webp";
import col3_1 from "../image/col3-1.webp";
import col3_2 from "../image/col3-2.webp";
import col3_3 from "../image/col3-3.webp";
import col3_4 from "../image/col3-4.webp";
import col3_5 from "../image/col3-5.webp";

// 4
import col4 from "../image/col4.webp";
import col4_1 from "../image/col4-1.webp";
import col4_2 from "../image/col4-2.webp";
import col4_3 from "../image/col4-3.webp";
import col4_4 from "../image/col4-4.webp";
import col4_5 from "../image/col4-5.webp";

// 5
import col5 from "../image/col5.webp";
import col5_1 from "../image/col5-1.webp";
import col5_2 from "../image/col5-2.webp";
import col5_3 from "../image/col5-3.webp";
import col5_4 from "../image/col5-4.webp";
import col5_5 from "../image/col5-5.webp";

// 6
import col6 from "../image/col6.webp";
import col6_1 from "../image/col6-1.webp";
import col6_2 from "../image/col6-2.webp";
import col6_3 from "../image/col6-3.webp";
import col6_4 from "../image/col6-4.webp";

export const productList = [
  {
    id: 1,
    title: "Big Cat Door",
    description: [
      {
        title: "Open the door to a world of happiness for your pet",
        description: [
          "Unlock freedom for your crafty cat of any size with the Big Cat Door. No more meowing or pawing when they need you to open a door – now they can roam freely! This interior and exterior pet door redefines safety and security for your best friend with two new features: Push-&-Turn™ 4-Way Lock and GentleClose™ flap. The Push-&-Turn™ 4-Way Lock ensures the knob stays in the setting you want, even when tested by the most determined paws. When it’s unlocked, GentleClose™ tech lets your cat clear the door flap before it softly lowers",
          "Keep your curious cat safe and secure with PetSafe’s new and improved Big Cat Door.",
        ],
      },
      {
        title: "Features",
        description: [
          "Interior and exterior pet door gives cats and small dogs up to 25 lb the ability to come and go on their own",
          "Push-&-Turn™ 4-Way locking mechanism ensures knob stays in setting you want (exclusive to Big Cat Door)",
          "4 lock settings to choose from: locked, unlocked, in-only, and out-only",
          "GentleClose™ technology allows flap to close gently and quietly, giving extra clearance for your cat’s tail (exclusive to Big Cat Door)",
          "Clear door flap allows cats to see through, reducing anxiety and making entry/exit decisions more confident",
          "Easy DIY installation with step-by-step instructions and cutting template, fits doors 1” to 2” thick (glass door installation requires adaptor)",
          "Weather strips reduce air flow and magnets at bottom of flap ensure door closes every time",
        ],
      },
      {
        title: "A cat door that gives you full control",
        description: [
          "The Push-&-Turn™ 4-Way lock gives you control that you can trust while giving your cat plenty of freedom. This satisfying push-to-turn lock has four modes: locked, unlocked, in-only and out-only. It’s cat-proof, meaning no matter how determined your cat swipes, paws and bites the knob, it only turns for you.",
        ],
      },
      {
        title: "World’s first soft-close pet door flap",
        description: [
          "Experience peace of mind and peace and quiet with GentleClose™ tech, exclusive to the Big Cat Door. GentleClose™ ensures the flap closes softly and quietly behind your cat, eliminating sudden shutting that could startle or harm them, just like soft close cabinets and drawers.",
        ],
      },
      {
        title: "Use as an interior or exterior pet door",
        description: [
          `Whether you're granting your cat access to a room with their food and litter box or to the great outdoors, the Big Cat Door is the perfect solution. The clear door flap allows your cat to see through, reducing anxiety and making entry or exit decisions more comfortable. With added weather stripping, it also minimizes airflow, enhancing home comfort. Plus, its installation is an easy DIY project, ideal for a weekend, making it convenient for you.`,
          "Your pet deserves the best. Trust PetSafe® to keep your pet healthy, safe and happy.",
        ],
      },
    ],
    quantity: 1,
    image: [col1],
    sku: [
      { title: "Installation", value: "Exterior/interior" },
      { title: "Frame Material", value: "Durable plastic" },
      { title: "Flap Material", value: "Hard plastic" },
    ],
    star: 4.2,
    price: "39.95",
    total: "39.95",
  },
  {
    id: 2,
    title: "ScoopFree® SmartSpin™ Self-Cleaning Litter Box",
    description: [
      {
        title: "Ditch the scooper and put a new spin on your litter box",
        description: [
          "Level up your litter box and tap into your pet’s health with the ScoopFree® SmartSpin™ Self-Cleaning Litter Box. SmartSpin sifts your cat’s clumps and waste into a sealed, deodorized waste bin giving you 4x better odor control vs. traditional litter boxes. The integrated app tracks your cat's weight and litter box usage, providing essential insights into their well-being, so you're always informed about their health. The automatic cleaning gives your cat a fresh bed of their favorite litter every time. Say goodbye to scooping forever and join the millions of pet parents worldwide living a life of ScoopFreedom® today!",
        ],
      },
      {
        title: "Features",
        description: [
          "SmartSpin™ tech senses when your cat has safely exited and quietly sifts away waste into a sealed drawer",
          "Offers 4x better odor control compared to traditional litter boxes, featuring a sealed waste drawer and integrated deodorizer",
          "PetSafe® SmartSpin™ app gives you real-time health data including weight updates and litter box usage from your phone (available on Google Play Store and App Store). Wi-Fi required for app connectivity (2.4 GHz).",
          "6-liter waste drawer holds up to 2 weeks of clumps and waste",
          "Smooth finish on interior prevents litter from getting caught, ensuring a deeper clean; easy to maintain with simple disassembly, wipe down, and reassembly",
          "Ideal for multi-cat households – provides a fresh bed of litter after every use to support multiple cats",
          "Easy-to-use control panel provides on-demand cleaning, child lock, weight change detection, waste drawer capacity and more",
          "Built with your cat’s safety in mind, just 12 in. from the ground with a cozy design for cats up to 25 lb",
          "Compatible with any litter, making it an easy and quick transition for your cat. For optimal performance, use a premium clumping litter.",
        ],
      },
      {
        title:
          "The automatic litter box that just works – so you don’t have to",
        description: [
          "With a low-profile design that’s easy for cats of all sizes to get in and out of, it’s no mystery why smart cats choose the ScoopFree® SmartSpin™. After your cat uses the litter box, ScoopFree SmartSpin quietly sifts and disposes of clumps, giving you up to two weeks of hands-free cleaning. The compact, modern design fits seamlessly into any room, providing functionality without sacrificing style.",
        ],
      },
      {
        title: "Peace of mind in the palm of your hand",
        description: [
          "Never miss a detail with the PetSafe® SmartSpin™ App. Whether you're at home or away, you can monitor your cat's health through weight tracking and litter box usage. Real-time updates and alerts keep you connected to your pet's well-being at your fingertips.",
        ],
      },
      {
        title: "A clean litter box is a happy litter box",
        description: [
          "We know pet parents are a busy bunch – that’s why we made the ScoopFree SmartSpin so easy to maintain and clean. Every couple of weeks, refill the litter box with your cat’s favorite litter and replace the waste bin drawer with a new liner. For a deeper clean, we’ve designed the interior with a smooth finish that doesn’t have any grooves. That means no litter getting caught in any cracks. Simply disassemble, wipe, reassemble, and you’re good to go!",
          "Join the millions of pet parents who have chosen the ScoopFreedom lifestyle. Upgrade to a ScoopFree SmartSpin today for a breath of fresh air!",
        ],
      },
    ],
    quantity: 1,
    image: [col2],
    sku: [
      { title: "Litter Box Type", value: "Self-cleaning" },
      { title: "Odor Control", value: "4X better odor control" },
      { title: "Wi-Fi Features", value: "24/7 app monitoring" },
      { title: "Compatible Litter", value: "All litter types" },
      { title: "Family Size", value: "Single or multi-cat house" },
    ],
    star: 4.5,
    price: "349.95",
    total: "349.95",
  },
  {
    id: 3,
    title: "Guardian® GPS + Tracking Dog Fence Collar",
    description: [
      {
        title: "The world's safest GPS dog fence",
        description: [
          "Keep your best friend safe and found with the Guardian® GPS + Tracking Dog Fence Collar. Our patented AccuGuard™ Technology gives you the most reliable and accurate way to track your dog’s every adventure. With the ability to save up to 20 wireless dog fences to Guardian GPS Fence + Tracking, you can bring peace of mind anywhere you go (even if it’s just the backyard). Trust the GPS dog fence made by the company that’s protected over 11 million dogs worldwide. Trust Guardian GPS + Tracking Dog Fence Collar!",
        ],
      },
      {
        title: "Features",
        description: [
          "Live tracking and precise GPS location let you see your dog’s real-time movement on the map",
          "AccuGuard™ tech blends GPS data, real-time motion detection and AI to ensure advanced protection that no other GPS dog fence collar can provide",
          "Create up to 20 custom wireless dog fence boundaries for life at home or on the go",
          "Guardian GPS + Tracking Dog Fence works on properties larger than 3/4 acre; if your yard is smaller than 3/4 acre or has dense tree coverage, try one of our in-ground or wireless fences",
          "Subscription required – Get 25% off your first year compared to a monthly plan when you sign up for an annual plan (see price chart)",
          "Stay connected with real-time alerts via the My PetSafe® app keeping you informed about your dog’s safety and location; activate Lost Pet Mode for location updates when you need them most",
          "Choose between tone and vibration or 10 levels of static to customize correction for your dog’s needs",
          "Quick charging within 2 to 4 hours and enjoy a long-lasting battery life of up to 48 hours, ensuring your dog’s safety around the clock",
          "Easily set up your Guardian® GPS + Tracking Dog Fence Collar in 1 to 2 hours (Wi-Fi required for setup but not for continued use)",
          "Designed for dogs weighing over 30 pounds with neck sizes ranging from 15 to 28 inches",
          "Train your dog for success with included 2-week step-by-step training guide",
        ],
      },
      {
        title: "Your go-to GPS dog fence",
        description: [
          "With the My PetSafe® app, you’re only a couple of taps away from knowing exactly where your dog is at all times. On a road trip? Don’t worry – you can save up to 20 virtual fences in the app so you can give your pup off-leash fun when you’re visiting family. The My PetSafe® app will also keep you up to date on the whereabouts of your dog and the battery life of your collar.",
        ],
      },
      {
        title: "The only GPS dog fence technology you can trust",
        description: [
          "Relax and achieve peace of mind with Guardian's AccuGuard™ technology. Our patented GPS dog fence tech combines satellite data, motion detection and AI for unbeatable boundary precision, accurate within 4 feet. AccuGuard™ predicts your dog's movements and warns them before crossing the boundary, providing superior protection even in wooded areas or when the GPS signal is weak. Trust the world's most accurate GPS dog fence technology to keep your best friend safe and secure.",
        ],
      },
      {
        title: "No install needed – just set up and unleash joy",
        description: [
          "Adventure waits for no one. Setting up your Guardian GPS + Tracking device is as easy as putting the collar on your dog and following a few prompts on your app. After a bit of training and exploring from your pup, you and your dog will be Guardian GPS Fence + Tracking experts in no time.",
        ],
      },
      {
        title: "Enjoy peace of mind with a reliable GPS dog fence collar",
        description: [
          "Keep your dog contained in their wireless fence anywhere, anyplace, anytime with a waterproof collar that boasts up to 48 hours of battery life. Our GPS dog fence collar comfortably fits dogs weighing 30 pounds and up, with neck sizes between 15 to 28 inches. Customize your containment options with tone, vibration and 10 levels of static correction to suit your dog’s unique needs.",
          "Your dog deserves the best. Trust PetSafe® to keep them healthy, safe and happy.",
        ],
      },
    ],
    quantity: 1,
    image: [col3],
    sku: [
      { title: "Coverage Area", value: "For large properties over 3/4-acre" },
      { title: "Boundary Shape", value: "Customizable" },
      { title: "Custom, Virtual Fences", value: "Store up to 20" },
      { title: "Setup Time", value: "1-2 hours" },
      { title: "Correction Type", value: "Static & tone with vibration" },
      { title: "Receiver Collar Type", value: "Rechargeable & waterproof" },
    ],
    star: 4.9,
    price: "399.95",
    total: "399.95",
  },
  {
    id: 4,
    title: "Outlast™ Cat Fountain, 90 oz",
    description: [
      {
        title: "The World’s First Pumpless Pet Fountain",
        description: [
          "Meet the PetSafe® Outlast™ 90 oz Pumpless Pet Fountain – the world’s first cat fountain without a traditional pump! Our patented HydroSpin™ technology keeps water flowing and eliminates the pain points that come with traditional fountains. The filter system keeps water tasting and looking fresh, giving your pet a healthier hydration experience. Ditch the standard pet fountain for something revolutionary – choose Outlast Pumpless Fountain.",
        ],
      },
      {
        title: "Features",
        description: [
          "A groundbreaking pet hydration solution with a unique pump-free design for uninterrupted water flow",
          "Features HydroSpin™ technology with a patented cone mechanism, cycling 90 oz of water into your cat’s drinking bowl",
          "Easy to assemble, dishwasher-safe, and pump-free, ensuring more quality time with your pet",
          "Designed for longevity with fewer moving parts and a motor protected from water and debris, redefining the reliability of cat fountains",
          "Ensures fresh, clean water every time with Ion-X™ Pet Fountain Filters to soften and improve the taste of your cat’s water, encouraging healthy hydration",
          "Smart, quiet motor with sensors to regulate water flow, automatically shutting off at low levels for an extended motor life",
          "LED indicators let you know when water is low and when to change the filter",
        ],
      },
      {
        title: "The Cat Water Fountain That’s Built to Last",
        description: [
          "Outlast’s HydroSpin™ Technology features a unique cone mechanism that cycles 90 oz of water into your cat’s drinking bowl, all without a submersible pump. The motor is built with premium stainless-steel ball bearings and sits away from water, meaning it never needs cleaning. To extend the life of the motor, Outlast automatically slows the water flow at night and shuts off when water levels are too low. ",
        ],
      },
      {
        title: "Pure, Clean Water Every Time – Health’s at the Forefront",
        description: [
          "Cats are naturally attracted to drink from fresh, flowing water. While HydroSpin keeps the water flowing, the included Ion-X™ Filter keeps it tasting great. The activated-carbon portion of the filter catches debris, keeping your pet’s water fresh while the ion-exchange resin keeps it soft. The combination of our Ion-X™ Filter and HydroSpin tech removes stagnation, preventing bacterial growth.",
        ],
      },
      {
        title: "Less Time Cleaning, More Time Together",
        description: [
          "Sure, we’re obsessed with pets. But we’re also obsessed with making your life easier. Traditional pump-driven fountains are tedious to clean and might be the biggest hang-up of pet parenthood. That’s why we eliminated the submersible pump completely, making this the easiest fountain to clean on the market. Plus, with dishwasher-safe parts, upkeep is as simple as enjoy, clean and repeat.",
          "Your cat deserves the best. Trust PetSafe® to keep your cat hydrated, healthy and happy.",
        ],
      },
    ],
    quantity: 1,
    image: [col4],
    sku: [
      { title: "Design", value: "World's 1st pumpless" },
      { title: "Product Care", value: "Top-rack dishwasher safe" },
      { title: "Capacity", value: "90 oz" },
      { title: "Water Flow", value: "HydroSpin" },
      { title: "Reminders", value: "LED monitor" },
      { title: "Filtration", value: "Ion-X" },
    ],
    star: 4.5,
    price: "69.95",
    total: "69.95",
  },
  {
    id: 5,
    title: "Easy Walk® Comfort No-Pull Dog Harness",
    description: [
      {
        title: "More control for you, more comfort for your dog",
        description: [
          "A bit of extra padding goes a long way – especially when you’re headed on a walk with your best friend. The Easy Walk® Comfort Harness is designed with your dog’s comfort in mind while giving you the confidence you want. Its boosted padding will put a pep in their step and the no-pull design will deter even the most persistent puller. It’s a win-win for you and your dog!",
          "Gear up for your next walk around the park or run around the block with the Easy Walk® Comfort Harness, the cushy no-pull dog harness made by the #1 brand to stop pulling.",
        ],
      },
      {
        title: "Features",
        description: [
          "Provide ultimate comfort for your dog with cushioned, premium, full body padding",
          "Patent-pending design featuring a front martingale loop helps train and reduce pulling on walks",
          "Switch to the back clip for running and other everyday activities",
          "Strong top handle for quick control when you need it most",
          "5 points of adjustability to provide the perfect fit",
          "Made with durable fabric with reflective material for visibility in low-light situations (available in Black and Blue)",
          "Rests across your dog’s chest instead of their throat to stop gagging or choking",
        ],
      },
      {
        title: "Train your dog to stop pulling on leash",
        description: [
          "With the My PetSafe® app, you’re only a couple of taps away from knowing exactly where your dog is at all times. On a road trip? Don’t worry – you can save up to 20 virtual fences in the app so you can give your pup off-leash fun when you’re visiting family. The My PetSafe® app will also keep you up to date on the whereabouts of your dog and the battery life of your collar.",
        ],
      },
      {
        title: "Our most comfortable no-pull dog harness",
        description: [
          "Your dog's comfort is our top priority. That's why the Easy Walk® Comfort Harness is designed with high-quality, full body padding to provide the most comfortable walking experience possible. While most “comfort” harnesses on the market are made from thin materials, our harness is made to last, providing support and cushioning for your dog.",
        ],
      },
      {
        title: "The dog harness recommended by vets and trainers",
        description: [
          "Join the millions of satisfied dog owners who trust PetSafe's dog harnesses to improve their walks. Our walking solutions have transformed the walking experience for over 17 million dogs worldwide and come highly recommended by vets and trainers.",
        ],
      },
    ],
    quantity: 1,
    image: [col5],
    sku: [
      { title: "Perfect Use", value: "For out and about" },
      { title: "Comfort Feature", value: "Premium padding" },
      { title: "Night Feature", value: "Reflective stitching" },
      { title: "Attachment Points", value: "Front or back" },
      { title: "Designed By", value: "A vet behaviorist" },
      { title: "Adjustment Points", value: "4" },
    ],
    star: 4.8,
    price: "34.95",
    total: "34.95",
  },
  {
    id: 6,
    title: "Viva™ 140 oz Pet Fountain",
    description: [
      {
        title: "Clean, healthy and convenient",
        description: [
          "The Viva™ Pet Fountain is a game-changer for your dog's hydration. This fountain for dogs keeps the water fresh and flowing, making them want to drink more throughout the day. The water is clean and tasty, thanks to the dual filtering that eliminates impurities and enhances flavor. Traditional water bowls can't compete with this kind of service. It's not just a treat for your dog - pet parents can ditch the daily hassle of filling and cleaning their pet's water bowl! Give your dog a fountain they’ll fall in love with at first sip with the 140 oz Viva Pet Fountain.",
        ],
      },
      {
        title: "Features",
        description: [
          "140 oz water capacity is perfect for small to medium-sized dogs",
          "Bubbler-style dog fountain keeps fresh water flowing 24/7, encouraging your pet to drink more",
          "Dual filtration with Ion-X™ and foam filters keeps water looking and tasting great while extending the life of your pump",
          "Plastic water tray is food-grade safe, encouraging a more hygienic drinking experience for your cat",
          "Ultra-quiet pump features a low-water LED indicator and automatically shuts off when water is low",
          "Easy to assemble and clean – simply handwash the pump and place all other parts in dishwasher",
        ],
      },
      {
        title: "For a fresh and clean dog fountain",
        description: [
          "Everybody knows what a traditional water bowl looks like after just one day. The Viva Pet Fountain gets rid of the mess with its dual-layer filtration. The first layer, a foam filter, captures larger particles, enhancing the longevity of the fountain's pump. The secondary Ion-X™ Filter plays a crucial role in eliminating distasteful flavors and odors, ensuring your dog enjoys fresh water every time.",
        ],
      },
      {
        title: "Tapping into an instinct",
        description: [
          "Dogs are naturally attracted to flowing water. Our dog fountain mimics this allure, encouraging your pets to drink more and stay hydrated without the stale taste of a traditional water bowl. Viva Pet Fountain is ultra-quiet and keeps 140 oz. of water flowing to continuously provide a reliable supply of clean water.",
        ],
      },
      {
        title: "Up to 7x less cleaning than a traditional bowl",
        description: [
          "This isn’t just a life-upgrade for your dog. Viva™ Pet Fountains are much easier to clean than regular water bowls, needing up to 7 times less cleaning! Our dog fountain is designed to make things convenient for you, with a constant flow of water that means less refilling. It's a dog water fountain that makes your life easier while giving your pet what they need. Cleaning is also simple, with dishwasher-safe parts that allows for effortless maintenance.",
        ],
      },
    ],
    quantity: 1,
    image: [col6],
    sku: [
      { title: "Product Care", value: "Top-rack dishwasher safe" },
      { title: "Capacity", value: "140 oz" },
      { title: "Filtration", value: "Ion-X and foam filters" },
      { title: "Water Flow", value: "Soft bubbling" },
      { title: "Indicator", value: "Low-water LED" },
      { title: "Pump Type", value: "Submersible & quiet" },
    ],
    star: 4.6,
    price: "39.95",
    total: "39.95",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};

export const getRandomElements = (id) => {
  const result = [];
  const arrayCopy = [...productList]; // 创建数组的副本，以避免修改原始数组

  for (let i = 0; i < 4; i++) {
    let randomIndex;

    // 确保随机索引不与给定的 id 相同
    do {
      randomIndex = Math.floor(Math.random() * arrayCopy.length);
    } while (productList[randomIndex].id === id);

    const element = arrayCopy.splice(randomIndex, 1)[0];
    result.push(element);
  }

  return result;
};
