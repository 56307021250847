import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import AddIcon from "../icon/AddIcon";
import sec1 from "../image/sec1.jpg";
import sec2 from "../image/sec2.webp";
import sec2_1 from "../image/sec2-1.webp";
import sec2_2 from "../image/sec2-2.webp";
import sec2_3 from "../image/sec2-3.webp";
import sec2_4 from "../image/sec2-4.webp";
import sec2_5 from "../image/sec2-5.webp";
import sec2_6 from "../image/sec2-6.webp";
import sec2_7 from "../image/sec2-7.webp";
import sec2_8 from "../image/sec2-8.webp";
import sec2_9 from "../image/sec2-9.webp";
import sec2_10 from "../image/sec2-10.webp";
import sec2_11 from "../image/sec2-11.webp";
import svg2 from "../image/sec2.svg";
import svg2_1 from "../image/sec2-1.svg";
import svg2_2 from "../image/sec2-2.svg";
import svg2_3 from "../image/sec2-3.svg";
import svg2_4 from "../image/sec2-4.svg";
import svg2_5 from "../image/sec2-5.svg";
import svg2_6 from "../image/sec2-6.svg";
import svg2_7 from "../image/sec2-7.svg";
import svg2_8 from "../image/sec2-8.svg";
import svg2_9 from "../image/sec2-9.svg";
import svg2_10 from "../image/sec2-10.svg";
import svg2_11 from "../image/sec2-11.svg";
import sec4 from "../image/sec4.png";
import blue from "../image/blue.svg";
import sec5 from "../image/sec5.webp";
import sec5_1 from "../image/sec5-1.webp";
import sec5_2 from "../image/sec5-2.webp";
import sec5_3 from "../image/sec5-3.webp";
import Rating from "@material-ui/lab/Rating";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sec2: [
        {
          bg: sec2,
          title: "Today's Deals",
          svg: svg2,
        },
        {
          bg: sec2_1,
          title: "Fencing",
          svg: svg2_1,
        },
        {
          bg: sec2_2,
          title: "Doors",
          svg: svg2_2,
        },
        {
          bg: sec2_3,
          title: "Litter Boxes & Litter",
          svg: svg2_3,
        },
        {
          bg: sec2_4,
          title: "Training",
          svg: svg2_4,
        },
        {
          bg: sec2_5,
          title: "Mobility",
          svg: svg2_5,
        },
        {
          bg: sec2_6,
          title: "Harnesses & Leashes",
          svg: svg2_6,
        },
        {
          bg: sec2_7,
          title: "Travel",
          svg: svg2_7,
        },
        {
          bg: sec2_8,
          title: "Fountains & Feeders",
          svg: svg2_8,
        },
        {
          bg: sec2_9,
          title: "Parts & Accessories",
          svg: svg2_9,
        },
        {
          bg: sec2_10,
          title: "Toys",
          svg: svg2_10,
        },
        {
          bg: sec2_11,
          title: "New Puppy",
          svg: svg2_11,
        },
      ],
      sec5: [
        {
          bg: sec5,
          title: "Training",
        },
        {
          bg: sec5_1,
          title: "Play",
        },
        {
          bg: sec5_2,
          title: "Health",
        },
        {
          bg: sec5_3,
          title: "Pet Care",
        },
      ],
    };
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    const { sec2, sec5 } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <img src={sec1} alt="" />
          </div>
          <div className="sec2">
            <div className="title">Explore Popular Categories</div>
            <div className="row">
              {sec2.map((item, index) => {
                return (
                  <div className="col" key={index}>
                    <div className="img">
                      <img src={item.bg} alt="" />
                      <div className="svg">
                        <img src={item.svg} alt="" />
                      </div>
                    </div>
                    <div className="title">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec3">
            <div className="left">
              <div className="title">New Arrivals</div>
              <p>
                Unleash joy with our newest innovations designed to make pet
                parenting easier
              </p>
            </div>
            <div className="right">
              {productList.map((item, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                    onClick={() => this.routeProductDetail(item.id)}
                  >
                    <div className="top">
                      <div className="img">
                        <img src={item.image[0]} alt="" />
                      </div>
                      <div className="txt">{item.title}</div>
                      <div className="star">
                        <Rating
                          name="half-rating-read"
                          precision={0.1}
                          value={item.star}
                          readOnly
                          style={{
                            color: "#083874", // 自定义颜色
                          }}
                        />
                        ({item.star})
                      </div>
                    </div>
                    <div className="bottom">
                      <div className="price">${item.price}</div>
                      <div className="btn">
                        <ShoppingCart size={20} />
                        <AddIcon size={20} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec4">
            <img src={sec4} alt="" />
            <div className="blue">
              <img src={blue} alt="" />
              <div className="txt">
                <h3>Our Community</h3>
                <p>
                  Millions of pet parents trust us to bring joy to their pets
                  every day.
                </p>
              </div>
            </div>
          </div>
          <div className="sec5">
            <div className="title">Good Reads by PetSafe®</div>
            <p>
              We're your source for wag-worthy pet parenting tips and advice!
              From <br />
              adopting a new kitten to helping a senior dog get the most out of
              life, <br />
              we've got expert guidance to help you and your pet unleash a
              lifetime <br /> of joy together.
            </p>
            <div className="row">
              {sec5.map((item, index) => {
                return (
                  <div className="col" key={index}>
                    <img src={item.bg} alt="" />
                    <div className="mask">
                      <div className="txt">{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
