import React, { Component } from "react";
import "../style/success.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import ErrorIcon from "../icon/ErrorIcon";
import CloseIcon from "../icon/CloseIcon";

class Fail extends Component {
  constructor(props) {
    super(props);
    this.routeHome = this.routeHome.bind(this);
  }

  routeHome() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="pay-content">
          <div className="box">
            <CloseIcon size={20} className="close" onClick={this.routeHome} />
            <ErrorIcon size={30} color="red"/>
            <p>Fail</p>
            <div className="view">OK</div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Fail;
