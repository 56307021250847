import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import contactImg from "../image/contact.webp";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="sec1">
            <img src={contactImg} alt="" />
            <div className="mask">
              <div className="txt">Contact Us</div>
            </div>
          </div>
          <div className="contact-content">
            <div className="head-contact">
              <h3>UNITED STATES AND CANADA</h3>
              <p>Phone support available Mon. - Fri. 8am - 7pm ET</p>
              <p>Phone: 1-866-738-4379</p>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
