import React from "react";
const ErrorIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#e6e6e6",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="11357"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 64q190.016 4.992 316.512 131.488T960 512q-4.992 190.016-131.488 316.512T512 960q-190.016-4.992-316.512-131.488T64 512q4.992-190.016 131.488-316.512T512 64z m0 394.016l-104-104q-12-12-27.488-12t-27.008 11.488-11.488 27.008 12 27.488l104 104-104 104q-12 12-12 27.488t11.488 27.008 27.008 11.488 27.488-12l104-104 104 104q16 15.008 36.992 9.504t26.496-26.496-9.504-36.992L565.984 512l104-104q12-12 12-27.488t-11.488-27.008-27.008-11.488-27.488 12z"
      p-id="11358"
      fill={color}
    ></path>
  </svg>
);

export default ErrorIcon;
