import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/terms.scss";
import returnImg from "../image/returns-img.png";

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="return-page">
          <div className="title">Terms of Use</div>
          <div className="desc-content">
            <div className="title">
              This document was last updated on May 6, 2016
            </div>
            <p>
              Welcome to petsafe.com. Radio Systems Corporation and its
              affiliates (“RSC,” “we,” “us,” or “our”) design products and
              services that enhance the relationship between pets and their
              owners. When you visit or shop at petsafe.com, use RSC products or
              services, use RSC applications for mobile or use software provided
              by RSC (collectively, “Service(s)”) you do so subject to the
              following conditions.
            </p>
            <p>By using RSC Services, you agree to these conditions.</p>
            <p>Privacy Policy</p>
            <p>
              Any information that RSC collects through your use of our Services
              is subject to the RSC Privacy Policy, which is part of these
              Terms.
            </p>
            <p>Site Policies, Modification, and Severability</p>
            <p>
              Please review our other policies posted on this site. These
              policies also govern your use of our Services. We reserve the
              right to make changes to our Services, Policies, Service Terms,
              and these Terms of Use at any time. If any of these conditions
              shall be deemed invalid, void, or for any reason unenforceable,
              that condition shall be deemed severable and shall not affect the
              validity and enforceability of any remaining condition.
            </p>
            <p>Additional Policies Apply to Products Orders</p>
            <p>
              RSC’s Terms of Sale , Return Policy, and Warranty Information
              apply to purchases made through our store at www.petsafe.com. All
              orders placed are subject to RSC’s acceptance. We may accept,
              decline, or place limits on your order for any reason.
            </p>
            <p>Limitations of Services</p>
            <p>
              Intended Use. RSC Services are intended for your personal,
              non-commercial use. RSC grants you a limited, non-exclusive,
              non-transferable, non-sub licensable license to (1) access and
              view the RSC Content, (2) access and use the software and mobile
              applications provided by our Services, and (3) use the software
              that is embedded into RSC products as authorized in these Terms.
              This license is provided solely for your personal use and
              enjoyment of our Services as permitted in these Terms. You will
              not use, copy, adapt, modify, prepare derivative works based upon,
              distribute, license, sell, transfer, publicly display, publicly
              perform, transmit, broadcast or otherwise exploit the RSC Content,
              RSC Service or any portion thereof, except as expressly permitted
              in these Terms. No licenses or rights are granted to you by
              implication or otherwise under any intellectual property rights
              owned or controlled by RSC or its licensors, except for the
              licenses and rights expressly granted in these Terms. RSC Services
              are subject to sporadic interruptions and failures for a variety
              of reasons beyond our control, including Wi-Fi intermittency,
              service provider uptime, mobile notifications, and mobile
              carriers, among others. You acknowledge these limitations and
              agree that we are not responsible for any damages allegedly caused
              by the failure or delay of the Services. You acknowledge that you
              will not rely on the Services for any safety or critical purposes
              related to you or your pet.
            </p>
            <p>
              You represent and agree that you are at least 18 years of age.
            </p>
            <p>
              No Unlawful or Prohibited Use. Access and use of any and all
              Services are subject to these Terms and all applicable laws. You
              may not use our Services in any manner that could disable,
              overburden, or impair any www.petsafe.com server, or interfere
              with any other person’s use and enjoyment of our Services, other
              accounts, computer systems or networks connected to any
              www.petsafe.com server or to any Service, through hacking,
              password mining or any other means. You may not obtain or attempt
              to obtain any materials or information through any means not
              intentionally made available through the Services. Use of the
              Content for any purpose not expressly permitted in these Terms is
              prohibited. You are prohibited from engaging in, suggesting or
              encouraging illegal activity in connection with any Service and we
              will cooperate with law enforcement officials as appropriate. Your
              rights to use our Services may be further limited by federal law
              or the laws or regulations in your particular state or locality.
              RSC may, in its sole discretion, and at any time, modify or
              discontinue the Services, or limit, terminate or suspend your use
              of the Service. We are not obligated to monitor access or use of
              Services, RSC Content, or to review or edit any RSC Content, but
              we have the right to do so for the purpose of operating the
              Services, to ensure compliance with these Terms, and to comply
              with applicable law or other legal requirements. We reserve the
              right (but are not required) to remove or disable access to the
              RSC Service or any RSC Content at any time and without notice, and
              at our sole discretion, if we determine that the RSC Content or
              your use of our Services are objectionable or in violation of
              these Terms. We have the right to investigate violations of these
              Terms and any conduct that affects the Service.
            </p>
            <p>
              Temporary Suspension. The Services may be suspended temporarily
              without notice for security reasons, system failure, maintenance
              and repair, or other circumstances. You agree that you will not be
              entitled to any refund or rebate for such suspensions. RSC does
              not offer any specific uptime guarantee for the Services.
              Necessary Equipment. Full use of RSC Services are dependent upon
              your use of a computer with adequate software or a supported
              mobile device and Internet access. The maintenance and security of
              this equipment may influence the performance of the RSC Service
              and it is your responsibility to ensure the equipment’s
              functionality. RSC may from time to time develop patches, bug
              fixes, updates, upgrades, and other modifications to improve the
              performance of RSC Services and or the Product Software
              (“Updates”). It is your sole responsibility to install all
              Updates. Pet Health and Other Benefits. RSC does not guarantee or
              promise any specific level of pet weight loss, pet health, or
              other benefit from the use of the Products or Services, or any
              feature of them. Actual results from use of the Services and
              Products vary with factors beyond RSC’s control or knowledge. You
              agree not to seek monetary or other remedies from RSC based on
              your results of using the Products and Services. The Services
              provide you information (“Product Information”) regarding the
              Products and their connection with other products and Services.
              All Product Information is provided “as is” and “as available”. We
              cannot guarantee that it is correct or up to date.
            </p>
            <p>Ownership, Copyright and Trademark</p>
            <p>
              You agree and acknowledge that RSC is the owner or licensee of all
              rights in the Services and (“RSC Content”) includes any text,
              graphics, images, music, software, audio, video, works of
              authorship of any kind, and information or other materials that
              are posted, generated, provided or otherwise made available
              through the Service to you. RSC Content, Service and its
              underlying technology are protected by copyright, trademark,
              patent, intellectual property, and other laws of the United States
              and foreign countries. You agree not to remove, change or obscure
              any copyright, trademark, service mark or other proprietary rights
              notices incorporated in or accompanying the Service. Any
              unauthorized use of any RSC Content may violate such laws. No
              links may be established to any part of the Service and no
              information within the Services may be framed without our prior
              written approval. Under no circumstances may you use any of the
              RSC Content whether owned by or licensed to us. Nothing within the
              Services should be construed as granting, by implication, estoppel
              or otherwise, any license or right to use any Trademarks without
              the written permission of the Trademark owner.
            </p>
            <p>DMCA/Copyright Policy</p>
            <p>
              RSC respects the intellectual property rights of others. If you
              believe that any of your work was copied by another, in a way that
              infringes your copyrights, and is used within our Services, please
              provide RSC with the following information: 1) a description of
              your work that you believe was infringed; 2) a description of the
              work believed to be infringing and where it appears within the
              Services; and 3) your contact information - name, address,
              telephone number and email address. This information should be
              accompanied by a statement that you believe, in good faith, that
              the infringing use is not authorized, and that, under penalty of
              perjury, all of the information in this notice is accurate and
              that you are the copyright owner or authorized to act on the
              copyright owner’s behalf. This notice should be signed and
              provided to RSC' designated copyright agent as set forth below:
            </p>
            {/* email */}
            <p>legal@petsafe.net</p>
            <p>
              Duration of this Agreement This agreement ("Agreement") will
              continue to be effective until you or we terminate it. We reserve
              the right to deactivate your account or terminate these Terms, at
              our sole discretion, at any time and without notice or liability
              to you. Upon any such termination, we may delete Your Content and
              other information related to your account. You may cancel your
              account at any time by contacting Customer Support. Upon any
              termination, discontinuation or cancellation of the Service or
              your account, the following provisions of these Terms will
              survive: RSC’s Rights; Termination; Disclaimers; Indemnity;
              Limitation of Liability; Jurisdiction and Governing Law; and
              General Terms.
            </p>
            <p>Information Provided by You</p>
            <p>
              The Services may provide you an opportunity to communicate with
              us. We are pleased to hear from our visitors and welcome your
              comments regarding our products and services. However, we do not
              want you to, and you agree through the use of the Services, that
              you will not send any confidential or proprietary information
              belonging to you or any other entity to us unless specifically
              requested by us. Any material information or idea you transmit to
              or post in connections with the Services by any means will be
              treated as non-confidential and non-proprietary, and may be
              disseminated or used by RSC or its affiliates for any purpose
              whatsoever, including, but not limited to, developing,
              manufacturing and marketing products. Notwithstanding the
              foregoing, personal data provided to RSC through the Service will
              be handled in accordance with the RSC Privacy Policy, which is
              available at this link, incorporated into these Terms and made a
              part hereof. Any contact or communication which you may make with
              us or our employees via our Services must be for RSC
              business-related purposes and all such communications are property
              of RSC, and subject to review and monitoring by us. You are
              prohibited from posting or transmitting to the Services any
              unlawful, threatening, libelous,
            </p>
            <p>
              defamatory, obscene, scandalous, inflammatory, pornographic,
              bigoted, hateful, vulgar, or profane material, or any other
              material that could give rise to any civil or criminal liability
              under the law. You understand that information transmitted over
              public networks may be accessed by third parties and RSC will not
              be liable for any such unauthorized disclosure. RSC and its
              employees do not accept or consider unsolicited ideas, including
              ideas for new advertising campaigns, new promotions, new products
              or technologies, processes, materials, marketing plans or new
              product names. Please do not send any original creative artwork,
              samples, demos or other works. The purpose of this policy is to
              avoid potential misunderstandings or disputes when RSC' products
              or marketing strategies might seem similar to ideas submitted to
              RSC. So, please do not send your unsolicited ideas to RSC or
              anyone at RSC. If, despite our request that you not send us your
              ideas and materials, you still send them, please understand that
              RSC makes no assurances that your ideas and materials will be
              treated as confidential or proprietary.
            </p>
            <p>Third Party Content and Linked Sites</p>
            <p>
              You acknowledge that the opinions and recommendations expressed or
              made available in the Services are those of the respective
              author(s) and are not necessarily those of RSC or endorsed by
              RSCs. The Services may contain links to other sites created and
              maintained by other organizations, which may or may not be related
              to RSC. These links are provided for your convenience and are for
              reference only and you agree that links do not imply that RSC is
              affiliated or associated with, or that any linked site is
              authorized to use any trademark, trade name, logo or copyright of
              RSC. You understand that the linked sites are not controlled by
              RSC and different legal terms and privacy policies may apply. We
              are not responsible for linked sites. We reserve the right to
              disable links, but we are not obligated to do so.
            </p>
            <p>Contact Us</p>
            <p>
              Please contact us if you have any questions about these terms.
            </p>
            <p>Radio Systems Corporation</p>
            <p>10427 PetSafe Way</p>
            <p>Knoxville, TN 37932</p>
            <p>legal@petsafe.net</p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
