import React from "react";

const CloseIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#423939",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2300"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M576 512l277.333333 277.333333-64 64-277.333333-277.333333L234.666667 853.333333 170.666667 789.333333l277.333333-277.333333L170.666667 234.666667 234.666667 170.666667l277.333333 277.333333L789.333333 170.666667 853.333333 234.666667 576 512z"
      fill={color}
      p-id="2301"
    ></path>
  </svg>
);

export default CloseIcon;
