import React, { Component } from "react";
import "../style/productDetail.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { getProductDetailById, getRandomElements } from "../apis/GetProduct";
import Truck from "../icon/Truck";
import Time from "../icon/Time";
import Number from "./common/Number";
import Rating from "@material-ui/lab/Rating";
import ShoppingCart from "../icon/ShoppingCart";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      isActive: 0,
      randomList: [],
    };
    this.clickChangeImage = this.clickChangeImage.bind(this);
    this.clickAddToCart = this.clickAddToCart.bind(this);
    this.routePage = this.routePage.bind(this);
  }
  headerRef = React.createRef();
  numberRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    let { location } = this.props;
    let id = location.pathname.replace("/ProductDetail/", "");
    let product = getProductDetailById(parseInt(id));
    this.setState({ product });
  }

  clickChangeImage(index) {
    this.setState({ isActive: index });
  }

  clickAddToCart(product) {
    let isProductInCart = false;
    let quantity = 0;
    if (this.numberRef) {
      product.quantity = this.numberRef.current.state.num;
    }

    if (this.headerRef) {
      let cartList = this.headerRef.current.state.cartList;

      if (cartList.length <= 0) {
        cartList.push(product);
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].id === product.id) {
            cartList[i].quantity += product.quantity;
            cartList[i].total = (
              cartList[i].quantity * parseFloat(product.price)
            ).toFixed(2);
            isProductInCart = true;
            break;
          }
        }

        if (!isProductInCart) {
          cartList.push(product);
        }
      }

      cartList.forEach((item) => {
        quantity += item.quantity;
      });

      this.headerRef.current.setState({ cartList, quantity });
      localStorage.setItem("cart", JSON.stringify(cartList));
      localStorage.setItem("quantity", JSON.stringify(quantity));
      this.routePage("cart");
    }
  }

  hoverChange(index) {
    let { randomList } = this.state;
    randomList[index].isHover = !randomList[index].isHover;
    this.setState({ randomList });
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }

  render() {
    let { product, isActive } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="productDetail-page">
          <div className="productDetail">
            <div className="row1">
              <div className="left-box">
                <div className="big-img">
                  <img src={product.image && product.image[isActive]} alt="" />
                </div>
                <div className="row2">
                  {product.description &&
                    product.description.map((item, index) => {
                      return index % 2 == 0 ? (
                        <div className="left-img" key={index}>
                          <div className="col">
                            <div className="title">{item.title}</div>
                            {item.description.map((item) => {
                              return <p className="desc">{item}</p>;
                            })}
                          </div>
                          <div className="col">
                            <div className="img-content">
                              <img src={item.img} alt="" />
                              <div className="text">{item.imgText}</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="left-img">
                          <div className="col">
                            <div className="img-content">
                              <img src={item.img} alt="" />
                              <div className="text">{item.imgText}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="title">{item.title}</div>
                            {item.description.map((item) => {
                              return <p className="desc">{item}</p>;
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="right-box">
                <div className="title">{product.title}</div>
                <div className="star">
                  <div className="left">
                    <Rating
                      name="half-rating-read"
                      precision={0.1}
                      value={product.star || 4}
                      readOnly
                      size="large"
                      style={{
                        color: "#3672d1", // 自定义颜色
                      }}
                    />
                    ({product.star})
                  </div>
                  <div className="tip">New !</div>
                </div>
                <div className="price">${product.price}</div>
                <div className="sku">
                  {product.sku &&
                    product.sku.map((item, index) => {
                      return (
                        <div className="col" key={index}>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="12"
                            width="12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM7 9.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"></path>
                          </svg>
                          <div className="sku-col">
                            <div className="sku-title">{item.title}</div>
                            <div className="sku-value">{item.value}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="btn-row">
                  <Number ref={this.numberRef} {...this.props} />
                  <div
                    className="addToCart"
                    onClick={() => this.clickAddToCart(product)}
                  >
                    <ShoppingCart size={24} />
                    ADD TO CART
                  </div>
                </div>
                <div className="delivery">
                  <div className="deli">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      class="fill-muted-foreground shrink-0"
                      height="18px"
                      width="18px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zM5 7V5h14v2H5zm5.56 10.46 5.93-5.93-1.06-1.06-4.87 4.87-2.11-2.11-1.06 1.06z"></path>
                    </svg>
                    <p>
                      {" "}
                      Order today to receive between September 29 - October 1
                    </p>
                  </div>
                  <div className="deli">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      class="fill-muted-foreground shrink-0"
                      height="18px"
                      width="18px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
                    </svg>
                    <p>This product qualifies for Free Shipping!</p>
                  </div>
                  <div className="deli">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      class="fill-muted-foreground shrink-0"
                      height="18px"
                      width="18px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M23 11.99 20.56 9.2l.34-3.69-3.61-.82L15.4 1.5 12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 11.99l2.44 2.79-.34 3.7 3.61.82 1.89 3.2 3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69 2.44-2.8zm-3.95 1.48-.56.65.08.85.18 1.95-1.9.43-.84.19-.44.74-.99 1.68-1.78-.77-.8-.34-.79.34-1.78.77-.99-1.67-.44-.74-.84-.19-1.9-.43.18-1.96.08-.85-.56-.65L3.67 12l1.29-1.48.56-.65-.09-.86-.18-1.94 1.9-.43.84-.19.44-.74.99-1.68 1.78.77.8.34.79-.34 1.78-.77.99 1.68.44.74.84.19 1.9.43-.18 1.95-.08.85.56.65 1.29 1.47-1.28 1.48z"></path>
                      <path d="m10.09 13.75-2.32-2.33-1.48 1.49 3.8 3.81 7.34-7.36-1.48-1.49z"></path>
                    </svg>
                    <p>
                      Satisfaction Guaranteed Return your product within 45 days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
