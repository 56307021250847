import React from "react";

const SuccessIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="12650"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 97.52381c228.912762 0 414.47619 185.563429 414.47619 414.47619s-185.563429 414.47619-414.47619 414.47619S97.52381 740.912762 97.52381 512 283.087238 97.52381 512 97.52381z m193.194667 218.331428L447.21981 581.315048l-103.936-107.812572-52.662858 50.761143 156.379429 162.230857 310.662095-319.683047-52.467809-50.956191z"
      fill={color}
      p-id="12651"
    ></path>
  </svg>
);

export default SuccessIcon;
