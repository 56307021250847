import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";
import aboutImg from "../image/about.png";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="sec1">
            <img src={aboutImg} alt="" />
            <div className="mask">
              <div className="txt">About PetSafe</div>
            </div>
          </div>
          <div className="about-content">
            <div className="head-contact">
              <h3>A 25 Year Legacy of Joy</h3>
              <p>
                In 1998, we founded PetSafe based on a simple truth: safe and
                healthy pets make for happy pets and happy pet families. Ever
                since, we’ve been growing and innovating non-stop to deliver
                life-changing products and experiences to a global community of
                pet parents. Our products bring a lifetime of joy to pets and
                the people who love them.​
              </p>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
